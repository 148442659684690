.res_container {
  text-align: center;
  margin-top: 5rem;
}

.back_button {
  margin-left: 9rem;
}

.customerinfo {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.colon_design {
  margin-left: 0.5rem;
}

.resultvalues_align {
  margin-left: 1rem;
}

.heading_align {
  margin-left: 1rem;
}

.message_container {
  text-align: center;
}

.back_button_design {
  text-align: end;
  margin-top: 1rem;
  margin-right: 3rem;
}
