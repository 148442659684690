.hidden {
  display: none;
}

.btn {
  background: #5F3384;
  color: #fff;
}

.btn:hover {
  color: #fff;
}

.container {
  margin-top: 5rem;
}

.button {
  margin-top: 1.7rem;
  margin-left: 2rem;
}

.heading {
  margin: 2rem;
  margin-left: 5rem;
}

.rev_button {
  border-color: rgb(67, 66, 67);
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.asf_onboarding_container_header {
  position: fixed;
  background-color: #5F3384;
  display: flex;
  width: 100%;
  height: 4rem;
  z-index: 10;
  justify-content: space-between;
  top: 0;
}

.asf_onboarding_container-right-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 3rem;
  margin: 0.5rem 1rem !important;
}
