.quote_container {
  display: flex;
  justify-content: center;
}

.rev_button {
  border-color: rgb(67, 66, 67);
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.result_container {
  text-align: center;
}